import { useEffect, useState, Component } from 'react';
import './App.css';
import contract from './Contracts/Buddiez.json';
import { ethers } from 'ethers';

const contractAddress = "0x80d933908D92d55e2e3538e6467e1fa80bEc3c48";
const abi = contract.abi;

const App = () => {

    const [currentAccount, setCurrentAccount] = useState(null);
    const [tokenPrice, setPrice] = useState(0);
    const [total, setTotal] = useState(0);
    const [maxMint, setMaxMint] = useState(0);
    const [g, setG] = useState(1);
    
    const { ethereum } = window;
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        provider.on("network", (newNetwork, oldNetwork) => {
            // When a Provider makes its initial connection, it emits a "network"
            // event with a null oldNetwork along with the newNetwork. So, if the
            // oldNetwork exists, it represents a changing network
            if (oldNetwork) {
                window.location.reload();
            }
            //console.log(newNetwork);
    });
    const signer = provider.getSigner();
    const nftContract = new ethers.Contract(contractAddress, abi, signer);

    var mintFigure = 0;
    let _maxMint = 0;
    let totMint = 0;
    let currentPrice = 0;
    let wEthPrice = 0;
    
    async function mintFigures() {
        if (ethereum) {
            document.getElementById("install").innerHTML = ("");
            console.log(nftContract);
            mintFigure = await nftContract.totalSupply();
            let nftPrice = "";
            nftPrice = await nftContract.PRICE();
            _maxMint = await nftContract.MAX_PER_MINT();
            _maxMint = parseInt(_maxMint);
            setMaxMint(_maxMint);
            console.log(maxMint);
            nftPrice = nftPrice / 1000000000000000000;
            console.log(nftPrice);
            currentPrice = nftPrice;
            setPrice(currentPrice);
            totMint = await nftContract.MAX_SUPPLY();
            //document.getElementById('total').innerHTML = ((g * currentPrice) + " $MATIC");
            //document.getElementById("price").innerHTML = (nftPrice + " $MATIC ("+ethPrice+"$wETH) Each!<br><br>");
            document.getElementById("progress").innerHTML = ("<h2>" + mintFigure + "/"+totMint+" Minted!</h2>");
        } else {
            document.getElementById("demo").innerHTML = ("<h2>OOPS</h2>");
        }
        
    }
    
    //var curNetwork = provider.getNetwork();
    //var netName = curNetwork.name;
    
    const checkWalletIsConnected = async () => {
        // const { ethereum } = window;

        if (!ethereum) {
            //alert("Please install Metamask!");
        } else {
            console.log("Wallet exists! We're ready to go!");

            var selAdd = await ethereum.request({ method: 'eth_accounts' });

            if (selAdd.length !== 0) {
                console.log(selAdd[0]);
                setCurrentAccount(selAdd[0]);

                if (mintFigure == 0) { mintFigures(); }


            } else {
                console.log("No authorised account was found.")
            }

            var curNetwork = await provider.getNetwork();
            var netName = curNetwork.chainId;
            if (netName != 1 && netName != 3) {
                document.getElementById("demo").innerHTML = "<div style='color:red;'> Please make sure you are connected to the Polygon Network!</div>";
            }


        }

    }
    

    const upOrDown = () => {
        var _g = g + 1
        if (g < maxMint) {
            setG(_g);
        }
        let nftPrice = tokenPrice;
        setTotal(nftPrice * _g);
        console.log(_g);
        console.log(nftPrice * _g);
        return;
    }
    const downJustDown = () => {
        var _g = g - 1
        if (g > 1) {
           setG(_g);
        }
        let nftPrice = tokenPrice;
        setTotal(nftPrice * _g);
        console.log(_g);
        console.log(nftPrice * _g);
        return;
    }

    const connectWalletHandler = async () => {
        if (!ethereum) {
            alert("Please install Metamask!");
        }

        try {
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            console.log("Found an account! Address: ", accounts[0]);
            setCurrentAccount(accounts[0]);
            if (mintFigure == 0) { mintFigures(); }
        } catch (err) {
            document.getElementById("demo").innerHTML = "<div style='color:red;'>"+(err)+" </div>";
        }
    }


    const mintNftHandler = async () => {
        try {
            if (ethereum) {
                var curNetwork = await provider.getNetwork();
                var netName = curNetwork.chainId;
                if (netName >= 0 || netName <= 3) {
                    var mintNum = g;
                    console.log(mintNum);
                    let nftPrice = currentPrice;
                    console.log(tokenPrice);
                    document.getElementById("log").innerHTML = ("Initialise payment...<br>" + document.getElementById("log").innerHTML);
                    let nftTxn = await nftContract.mintNFTs(mintNum, { value: ethers.utils.parseEther((tokenPrice*mintNum).toString()) });
                    console.log("Hmm...");
                    document.getElementById("log").innerHTML = ("Waiting for transaction...<br>" + document.getElementById("log").innerHTML);
                    await nftTxn.wait();
                    document.getElementById("log").innerHTML = ("Success! See transaction <a href='https://polygonscan.com/tx/" + nftTxn.hash + "' >here.</a><br>" + document.getElementById("log").innerHTML);
                } else {
                    document.getElementById("demo").innerHTML = "<div style='color:red;'> Please make sure you are connected to the Polygon Network!</div>";
                }
            } else {
                console.log("Ethereum object does not exist");
            }

        } catch (err) {
            console.log(err);
            document.getElementById("log").innerHTML = (err.message + "</br>" + document.getElementById("log").innerHTML);
            if (err.data != null) {
                
                document.getElementById("demo").innerHTML = err.data.message;
            }

        }

    }

    const connectWalletButton = () => {
        return (
            <button onClick={connectWalletHandler} className='cta-button connect-wallet-button'>
                Connect Wallet
            </button>
        )
    }

    const mintNftButton = () => {
        var mintNum = g;
        let nftPrice = tokenPrice;
        let _tot = mintNum * nftPrice;
        return (
            <>
                <button onClick={mintNftHandler} className='cta-button mint-nft-button'>
                    Mint for {_tot} $ETH
                </button>
            </>
        )
    }

    const incMint = () => {
        return (
            <button onClick={upOrDown} className='cta-button plus-minus-button'>
                +
            </button>
        )
    }
    const decMint = () => {
        return (
            <button onClick={downJustDown} className='cta-button plus-minus-button'>
                -
            </button>
        )
    }
    useEffect(() => {
        checkWalletIsConnected();
    }, [])
    let myURL = "https://polygonscan.com/address/" + currentAccount;
    return (
        <div className='main-app' id="main-app">
            <div id="right" className="2-columns">Patient:<br></br>
                <div><a href={myURL}>{currentAccount}</a>            </div></div>
            <div id="left" className="2-columns"><div id="progress"></div></div>
            <div id="right" className="2-columns"><label htmlFor="num">Required Dosage:</label>
            <br></br>
            {decMint()}     <label id="up" >Mint {g} NFTs</label>   {incMint()}
            <br></br>
            </div><br></br>
            <div id="wide" className="wide">
                {currentAccount ? mintNftButton() : connectWalletButton()}
            </div>
            
        </div>
    )
}

export default App;